import { AxiosError, AxiosResponse } from 'axios';

import { Routes } from '@constants/routes';
import { NavigateFunction } from 'react-router';
import API from './API';
import StorageHelpers from './StorageHelpers';
// Routes
const { LOGIN, SIGNUP, VERIFY_EMAIL_REGISTRATION, LOGOUT } = Routes;

const logout = (navigate: NavigateFunction, redirectLocation: any) => {
  StorageHelpers.clearOnLogout(); // Delete Pawprint localstorage
  sessionStorage.clear();
  // remove custom branding classes from the body tag
  document.getElementsByTagName('body')[0].classList.remove('uoe');
  navigate(redirectLocation); // Redirect to login screen
};

/**
 * Redirect the user to the login page
 */
export const redirectOnError = (errorMessage: string, navigate: NavigateFunction) => {
  if (typeof window !== 'undefined') {
    // Client side
    logout(navigate, { pathname: SIGNUP, search: `?errorMessage=${errorMessage}` });
  }
};

/**
 * Redirect the user to the verification page
 */
export const redirectToVerify = (navigate: NavigateFunction) => {
  if (typeof window !== 'undefined') {
    navigate(VERIFY_EMAIL_REGISTRATION);
  }
};

/**
 * Handles all the logout functionality
 */
export const UserLogout = async (navigate: NavigateFunction) =>
  API.get(LOGOUT, null, {}, (response: AxiosResponse) => {
    logout(navigate, LOGIN);
    return response; // Return the response status
  }).catch(
    (errRes: AxiosError) => errRes, // Return the error response
  );

/**
 * Handles all the delete account functionality
 */
export const UserDelete = async (secureUserIri: string, navigate: NavigateFunction) =>
  API.post('/secure_user_account_delete_requests', { secureUser: secureUserIri }, {}, (response: AxiosResponse) => {
    logout(navigate, LOGIN);
    return response; // Return the response status
  }).catch(
    (errRes: AxiosError) => errRes, // Return the error response
  );

/**
 * Checks if the user's bearer token is invalid or missing \
 * If not, send to /login
 */
export const AuthenticateBearerToken = async (secureUserId: string, navigate: NavigateFunction) => {
  API.get(`/check_auths/${secureUserId}`, {}, { withCredentials: true }, (status: number, data: any, headers: any) => ({
    status,
    data,
    headers,
  })).catch((errRes: AxiosError) => {
    if (errRes.response?.status === 401) {
      // If the auth check returns a 401 response, the user session is invalid and the user will be redirected back to the login screen
      UserLogout(navigate);
    }
  });
};

/**
 * Check for the existence of the secure user Id
 */
export const CheckForSecureUserId = async (): Promise<string | null> => StorageHelpers.getItem('secureUserId');
