// Ensures that if the user is logged in, it will redirect them to the / route

import { Routes } from '@constants/routes';

const {
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_EMAIL,
  VERIFY_ACCOUNT,
  PRIVACY,
  ERROR_PAGE,
  COOKIES,
  MAINTENANCE,
  USE_APP,
  MICROSOFT_REDIRECT,
} = Routes;

export const anonymousPagesToRedirect = [LOGIN, SIGNUP, VERIFY_EMAIL];
export const anonymousPages = [
  ...anonymousPagesToRedirect,
  VERIFY_ACCOUNT,
  PRIVACY,
  ERROR_PAGE,
  COOKIES,
  MAINTENANCE,
  USE_APP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  MICROSOFT_REDIRECT,
];
