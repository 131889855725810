import * as React from 'react';
import * as ReactDOM from 'react-dom';
import type { AppProps } from 'next/app';
// Polyfill to address older Safari browsers
import { install } from 'resize-observer';
import { axeConfig, axeContext } from '../axe.config';

import AppHead from '../components/organism/AppHead';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Structure CSS from Ionic libary with modififcations */
// import '../global/structure.css';
/* Optional CSS utils that can be commented out */
// import '@ionic/react/css/typography.css';

import '../global/index.scss';

import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

declare global {
  interface Window {
    _fs_asset_map_id: string;
  }
}

if (typeof window !== 'undefined') {
  install();
}

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_FS_ASSET_MAP_ID) {
  // eslint-disable-next-line no-underscore-dangle
  window._fs_asset_map_id = process.env.NEXT_PUBLIC_FS_ASSET_MAP_ID;
}

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000, axeConfig, axeContext);
}

const MyApp = ({ Component, pageProps }: AppProps) => (
  <>
    <AppHead />
    <Component {...pageProps} />
  </>
);
export default MyApp;
