/* eslint-disable camelcase */
import { UAParser } from 'ua-parser-js';
import { CheckForSecureUserId } from '@utils/Auth';
import { Routes } from '@constants/routes';
import { User } from '@appTypes/SecureUser';
import { getPlatforms, isPlatform } from '@ionic/react';
import { initialiseSegment } from './ThirdParty';
import StorageHelpers from './StorageHelpers';

// Routes
const { ROOT } = Routes;

declare global {
  interface Window {
    analytics: any;
    StonlyWidget: any;
  }
}

export const RoutesWithNoPageTracking = [Routes.SPRINT_RESULTS, Routes.PROGRESS_TRACK, Routes.SIGNUP];

export const initialiseAnalytics = (analyticsEnabled: boolean) => {
  initialiseSegment(analyticsEnabled);
};

export const IdentifyUser = (apiUser: User) =>
  (async () => {
    const platforms = getPlatforms();
    const userId = await StorageHelpers.getItem('secureUserId').catch((err) => {
      console.error(err);
    });

    const userAgentParser = new UAParser();

    const parsePlatformTypes = () => {
      if (isPlatform('capacitor')) return 'iOS';
      // although we are using pwa for android app, unsure if it will register as such when downloaded from play store
      if (isPlatform('android')) {
        if (!isPlatform('mobileweb')) return 'Android';
        if (isPlatform('pwa')) return 'web (pwa - installed)';
      }
      return 'web (browser)';
    };

    if (userId) {
      const { major: browser_major, name: browser_name, version: browser_version } = userAgentParser.getBrowser();

      const { model: device_model, vendor: device_vendor } = userAgentParser.getDevice();

      const { name: os_name, version: os_version } = userAgentParser.getOS();

      const analyticsObj = {
        ...(apiUser?.userProfile.organisation && {
          pawprintOrganisationId: apiUser.userProfile.organisation.iri,
          pawprintOrganisationName: apiUser.userProfile.organisation.name,
          pawprintOrganisationIsBusiness: apiUser.userProfile.organisation.isBusiness,
        }),
        appType: parsePlatformTypes(),
        platformInfo: platforms.join(','),
        device_type: platforms
          .filter((info) => ['tablet', 'desktop', 'mobile', 'phablet'].some((item) => item === info))
          .toString(),
        device_model,
        device_vendor,
        browser_major,
        browser_name,
        browser_version,
        os_name,
        os_version,
        app_id: process.env.NEXT_PUBLIC_PAW_APP_VERSION,
      };

      window.analytics?.identify(userId, analyticsObj);
    }
  })();

export const trimIdFromUrl = (url: string) => {
  const res = url.split('/');
  const finalIndex = res[res.length - 1];

  if (Number(finalIndex)) {
    res.pop();
  }
  return res.join('/');
};

const trimPawprintTitle = (title: string) => title.split('Pawprint: ')[1];

export const TrackPage = async (
  titleOverride?: string,
  urlOverride?: string,
  customVals?: { [key: string]: string | number },
) => {
  try {
    const userId = await CheckForSecureUserId();
    const isRootPage = window.location.pathname === ROOT && !!userId;

    let title = titleOverride || trimPawprintTitle(window.document.title);
    let url = urlOverride || trimIdFromUrl(window.location.pathname);

    // Non-standard tracking for root page
    if (isRootPage) {
      title = 'Homepage';
      url = '/pawprint';
    }

    // prevents undefined title submission (and double tracking before title updates)
    if (title) {
      window.analytics?.page({
        title,
        url,
        ...customVals,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

/**
 * Standard object collection of details for event tracking
 */
export interface TrackingObject {
  /**
   * ex: Community
   */
  context: string;
  /**
   * ex: Group Create
   */
  action: string;
  /**
   * ex: group id
   */
  id?: any;
  /**
   * ex: group name
   */
  readable?: any;
}

/**
 * Being phased out and replaced with TrackGACompatible
 * Track events
 */
export const Track: (context: string, action: string, identifier?: any, readable?: any) => void = (
  context,
  action,
  identifier,
  readable,
) => {
  const trackString = `${context} ${action}`;
  window.analytics?.track(trackString, {
    pawprintEventId: identifier,
    pawprintEventName: readable,
  });
};

export interface TrackGAProps {
  event: string; // description of action  e.g. 'Search actions'
  properties: {
    label?: string; // Specific identifier, e.g. 'toe/bucketName'
    value?: number; // a value associated with an action - eg, number of search results
    [key: string]: string | number | undefined; // other properties, undefined only to suffice the optional props above
  };
}

/**
 * Identify events
 */
export const Identify = async (traits: { [key: string]: string }) => {
  try {
    const userId = await StorageHelpers.getItem('secureUserId');
    window.analytics?.identify(userId, traits);
  } catch (err) {
    console.error(err);
  }
};

/*
 * Will be replacing Track as it gets phased out
 * @param event Specific event that happened
 * @param properties
 */
export const TrackGACompatible = ({ event, properties }: TrackGAProps) => {
  window.analytics?.track(event, properties);
};
