import React, { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import StorageHelpers from '@utils/StorageHelpers';
import { initialiseAnalytics } from '@utils/Analytics';

const AppHead = () => {
  useEffect(() => {
    StorageHelpers.getItem('cookiesPreferences').then((cookiesPreferences) => {
      if (cookiesPreferences) initialiseAnalytics(cookiesPreferences.value?.analyticsCookies);
    });
  }, []);

  return (
    <>
      <Head>
        <title>Pawprint</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=yes, viewport-fit=cover" />
      </Head>
      {/* eslint-disable-next-line no-console */}
      <Script src="/stonly-integration.js" onError={(e) => console.log(e)} />
      <Script id="stonly-wid">{`window.STONLY_WID = "befc6fc9-0d0c-11ef-a9d4-06cb0cb2a85e";`}</Script>
    </>
  );
};

export default AppHead;
