/**
 * This file contains the JavaScript snippet for integration with https://segment.com/
 *
 * The code has been formatted for readability and to comply with project formatting rules, and been amended
 * to use the `segmentWriteKey` variable when calling `analytics.load()` and to prevent `analytics.page()` being
 * called here as it's now called elsewhere. It has not otherwise been modified.
 *
 * Certain ESLint rules must therefore be disabled for the CI lint check to pass.
 */
const segmentWriteKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
const segmentCdnHost = process.env.NEXT_PUBLIC_SEGMENT_CDN_HOST;

/**
 * Keeping the file as js, as the snippet is provided by Segment Analytics and is not our own code
 */

/* eslint-disable no-console, no-multi-assign, no-unused-expressions,
prefer-rest-params, no-plusplus, no-underscore-dangle */
const SegmentSnippet = () => {
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize && !analytics.invoked) {
    analytics.invoked = !0;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];

    analytics.factory = function (t: any) {
      return function () {
        const e = Array.prototype.slice.call(arguments);
        e.unshift(t);
        analytics.push(e);
        return analytics;
      };
    };

    for (let t = 0; t < analytics.methods.length; t++) {
      const e = analytics.methods[t];
      analytics[e] = analytics.factory(e);
    }

    analytics.load = function (h: any, wk: any, e: any) {
      const n = document.createElement('script');
      n.type = 'text/javascript';
      n.async = !0;
      n.src = `https://${h}/analytics.js/v1/${wk}/analytics.min.js`;
      const a = document.getElementsByTagName('script')[0];
      a.parentNode?.insertBefore(n, a);
      analytics._loadOptions = e;
    };

    analytics.SNIPPET_VERSION = '4.1.0';
    analytics.load(segmentCdnHost, segmentWriteKey);
  }
};

export default SegmentSnippet;
