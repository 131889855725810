import axios, { AxiosError, AxiosInstance, AxiosResponse, CancelToken } from 'axios';
// Utils
import StorageHelpers from '@utils/StorageHelpers';
import { Routes } from '@constants/routes';
import { anonymousPages } from './AnonymousPages';

// Routes

const { LOGIN, MAINTENANCE } = Routes;

axios.defaults.baseURL = process.env.NEXT_PUBLIC_EXTERNAL_API_ENTRYPOINT;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['accept-version'] = process.env.NEXT_PUBLIC_API_VERSION as string;

/**
 * Set of helper functions to standardise the response & error handling for all API requests
 */
class API {
  maintenanceMessage = 'Maintenance ongoing please try again later.';

  unauthorizedMessage = 'Your authentication has expired please log in again';

  userExistsMessage = 'An account with that email address already exists.';

  service: AxiosInstance;

  constructor() {
    const service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess = (response: AxiosResponse) => response;

  handleError = (error: AxiosError) => {
    /**
     * Catch if the response is a 503 and add a more user friendly error message. If on the login screen do not try to redirect to maintenance.
     */
    if (error?.response?.status === 503) {
      // eslint-disable-next-line no-param-reassign
      error.message = this.maintenanceMessage;
      window.history.pushState(null, '', MAINTENANCE);
    }

    /**
     * Catch if the response is a 401 and add a more user friendly error message. Redirect to login screen to allow user to reauthenticate.
     */
    if (error?.response?.status === 401 && !anonymousPages.includes(window.location.pathname)) {
      // eslint-disable-next-line no-param-reassign
      error.message = this.unauthorizedMessage;
      StorageHelpers.clearOnLogout();
      window.history.pushState(null, '', LOGIN);
    }

    return Promise.reject(error);
  };

  get(path: string, headers: any, config: any, callback: any, cancelToken?: CancelToken) {
    return this.service
      .get(path, {
        headers,
        cancelToken,
        ...config,
      })
      .then((response) => callback(response.status, response.data));
  }

  patch(path: string, payload: any, config: any, callback: any) {
    return this.service
      .request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        ...config,
      })
      .then((response) => callback(response.status, response.data));
  }

  put(path: string, payload: any, config: any, callback: any) {
    return this.service
      .request({
        method: 'PUT',
        url: path,
        responseType: 'json',
        data: payload,
        ...config,
      })
      .then((response) => callback(response.status, response.data));
  }

  delete(path: string, config: any, callback: any) {
    return this.service
      .request({
        method: 'DELETE',
        url: path,
        responseType: 'json',
        ...config,
      })
      .then((response) => callback(response.status, response.data));
  }

  post(path: string, payload: any, config: any, callback: any) {
    return this.service
      .post(path, payload, config)
      .then((response) => callback(response.status, response.data, response.headers));
  }
}

export default new API();
