export const axeConfig = {
  rules: [
    {
      // 1.4.4 Resize text
      // zooming and scaling
      id: 'meta-viewport',
      enabled: false,
    },
    {
      // 3.1.1 Language of Page
      id: 'html-has-lang',
      enabled: false,
    },
    {
      // 1.4.3 Contrast (Minimum)
      id: 'color-contrast',
      enabled: false,
    },
  ],
};

export const axeContext = {
  exclude: [['.ion-page-hidden']],
};
