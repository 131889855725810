import { Preferences } from '@capacitor/preferences';

class StorageHelpers {
  getItem = async (key: string) => {
    let value = null;
    try {
      value = (await Preferences.get({ key })).value;
    } catch (error) {
      return null;
    }

    if (value) value = JSON.parse(value as string);

    // Check whether the local storage item was set with a time limit
    if (value?.expires) {
      const now = new Date();
      if (now.getTime() > value.expires) {
        localStorage.removeItem(key);
        return null;
      }
      value = value.item;
    }

    return value;
  };

  /**
   * Save data to the local storage as a string
   */
  setItem = async (key: string, value: any) => {
    try {
      await Preferences.set({
        key,
        value: JSON.stringify(value),
      });
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Save data to the local storage as a string AND with a 24hr lifespan
   */
  setItemWithExpiry = async (key: string, value: any) => {
    const itemWithExpiry = {
      item: value,
      expires: new Date().getTime() + 86400000,
    };
    await this.setItem(key, itemWithExpiry);
  };

  removeItem = async (key: string) => {
    try {
      await Preferences.remove({ key });
    } catch (error) {
      console.error(error);
    }
  };

  clear = async () => {
    try {
      await Preferences.clear();
    } catch (error) {
      console.error(error);
    }
  };

  // Clear all pawprint values from storage on logout, except for cookies preferences
  clearOnLogout = async () => {
    await this.removeItem('secureUserId');
    await this.removeItem('apiUserId');
    await this.clearStorageData();
  };

  // On Storage Expiration, we want to clear everything from storage, except the userId
  clearOnStorageExpiration = async () => {
    const secureUserId = await this.getItem('secureUserId');
    const apiUserId = await this.getItem('apiUserId');
    await Preferences.clear();
    if (secureUserId) await this.setItem('secureUserId', secureUserId);
    if (apiUserId) await this.setItem('apiUserId', apiUserId);
  };

  /**
   * Delete Pawprint localstorage data
   */
  clearStorageData = () =>
    (async () => {
      try {
        const keysResult = await Preferences.keys();
        keysResult.keys.forEach(async (key) => {
          if (/^(?:pp_)/.test(key)) {
            await Preferences.remove({ key });
          }
        });
      } catch (error) {
        console.error(error);
      }
    })();
}

export default new StorageHelpers();
