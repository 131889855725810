export const Routes = {
  ROOT: '/',

  ONBOARDING: '/onboarding',
  ONBOARDING_RESULTS: '/onboarding/results',
  ONBOARDING_RESULTS_VIEW: '/onboarding/results/:slug',
  ONBOARDING_SURVEY: '/onboarding/survey',
  ONBOARDING_SURVEY_VIEW: '/onboarding/survey/:slug',
  MANAGE_ANSWERS: '/manage-answers',
  QUESTION_SETS: '/question-sets',
  QUESTION_SETS_VIEW: '/question-sets/:pid',
  QUESTION: '/question-sets/:pid/:qid',

  PAWPRINT_SUMMARY: '/pawprint-summary',

  REWARDS: '/rewards',
  REWARDS_SUGGESTIONS: '/rewards/suggestions',
  REWARDS_CAUSES: '/rewards/causes',
  REWARDS_CAUSES_VIEW: '/rewards/causes/:pid',

  PROFILE: '/profile',
  PROFILE_SETTINGS: '/profile/settings',
  PROFILE_SETTINGS_EMAIL: '/profile/settings/email',
  PROFILE_SETTINGS_NAME_LOCATION: '/profile/settings/name-location',
  CHANGE_PASSWORD: '/profile/settings/change-password',
  PREFERENCES: '/profile/preferences',
  PROFILE_HELP_CENTRE: '/profile/help-centre',

  ACTION_REMINDER: '/profile/preferences/reminder',

  SEARCH: '/search',

  PROGRESS: '/progress',
  PROGRESS_REDIRECT: '/reduce',
  PROGRESS_TRACK: '/progress/actions/track',
  PROGRESS_TRACK_VIEW: '/progress/actions/track/:pid/:cid?',
  PROGRESS_TRACK_REDIRECT: '/reduce/actions/track/:pid',
  PROGRESS_SUGGEST: '/progress/actions/suggest',
  PROGRESS_HABIT: '/progress/habits',
  PROGRESS_HABIT_VIEW: '/progress/habits/:pid',
  PROGRESS_HABIT_REDIRECT: '/reduce/habits/:pid',
  PROGRESS_HABIT_CREATE: '/progress/habits/create',
  PROGRESS_HABIT_CREATE_VIEW: '/progress/habits/create/:action',
  PROGRESS_HABIT_PREVIEW: '/progress/habits/preview',
  PROGRESS_HABIT_PREVIEW_VIEW: '/progress/habits/preview/:pid',

  LEADERBOARD: '/leaderboard',
  LEADERBOARD_EDIT: '/leaderboard/edit',
  LEADERBOARD_SEGMENTS: '/leaderboard/segments',
  SPRINT_RESULTS: '/sprint-results',
  LEADERBOARD_ONBOARDING: '/leaderboard/onboarding',

  BUSINESS_SURVEY_ROOT: '/business-survey',
  BUSINESS_SURVEY_GROUP: '/business-survey/:groupId',
  BUSINESS_SURVEY_SURVEY: '/business-survey/:groupId/:surveyId',
  BUSINESS_SURVEY_RESPONSE: '/business-survey/:groupId/:surveyId/:responseId',
  BUSINESS_SURVEY_PAGE: '/business-survey/:groupId/:surveyId/:responseId/:pageId',
  BUSINESS_SURVEY_COMPLETE: '/business-survey/:groupId/:surveyId/:responseId/complete',
  SURVEY_COMPLETE: '/survey-complete',
  SURVEY_COMPLETE_VIEW: '/survey-complete/:pid',

  ALL: '*',

  LOGIN: '/login',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  MICROSOFT_REDIRECT: '/microsoft-redirect',
  USE_APP: '/use-app',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_ACCOUNT: '/verify-account',
  VERIFY_EMAIL: '/verify-email',
  VERIFY_EMAIL_REGISTRATION: '/verify-email-registration',

  PRIVACY: '/privacy',
  ERROR_PAGE: '/404',
  COOKIES: '/cookies',
  MAINTENANCE: '/maintenance',

  ORGANISATIONS: '/organisations',
  DYNAMIC_PID: ':pid',

  BLOG_ARTICLE: '/blog/:fixedId',
};
